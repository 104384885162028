<template>
  <nh-drawer
    v-model="dialogVisible"
    v-loading="loading.init"
    :before-close="handleClose"
    destroy-on-close
    title="进口品四证检测"
    size="704px"
    :track="{
      name_zh: `入库管理/${nameZh}/抽屉-进口品四证检测`,
      trackName: $route.path
    }"
  >
    <div class="content-box">
      <p>订单含有进口货品,请确认已上传“四证"至附件并同步本省市进口冷链食品追溯平台;</p>
      <p>
        要求上传信息包括:进口冷链食品入境检验检疫合格证明、核酸检测证明、消毒证明、
        溯源信息凭证(品种、规格、批次、数量、产地、流向等);部分省份还要求上传海关通关单证/报关单、集中仓出库证明。
      </p>
      <p>
        <nh-button
          type="text"
          :loading="loading.handleDownload"
          @click="handleDownload"
        >
          点击查看
        </nh-button>各省市进口品管理要求。
      </p>
    </div>
    <nh-upload-attachment
      v-model:upload-file="fileData"
      @onRemove="handleRemove"
      @onAllRemove="handleAllRemove"
    />
    <template #footer>
      <div class="footer">
        <nh-button
          type="grey"
          @click="handleClose"
        >
          取 消
        </nh-button>
        <nh-button
          v-loading="loading.onSubmitForm"
          type="primary"
          @click="onSubmitForm"
        >
          我已上传
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';
import { cloneDeep } from 'lodash';
import { BIZ_TYPE_ENUM } from '../constant';
import env from '../../../../../env/index';
import splitFiles from './splitFiles';

import {
  downLoadTemplate,
  fileAttachmentAdd, fileAttachmentBatchDelete,
} from '../api';

export default {
  name: 'FourCardTest',
  mixins: [loadingMixin],
  inject: ['nameZh'],
  emits: ['handleSuccess'],
  data() {
    return {
      dialogVisible: false,
      loading: {
        init: false,
        onSubmitForm: false,
        handleDownload: false,
        handleBeforeUpload: false,
        handleOnRemove: false,
      },
      upLoadUrl: `${env.OTMS_WEB_PLUS_SERVER}/bfs_oss/upload`,
      fileData: [],
      oldFileData: [],
      uploadData: {
        fileExtension: '',
      },
      uploadNum: 0,
      rowData: {},
    };
  },
  methods: {
    async init(rowData, fileData) {
      this.fileData = fileData;
      this.oldFileData = cloneDeep(fileData);
      this.rowData = rowData;
      this.dialogVisible = true;
    },
    async onSubmitForm() {
      if (this.fileData.length > 0) {
        const { addFile, deleteFile } = splitFiles(this.fileData, this.oldFileData);
        if (deleteFile.length > 0) {
          await fileAttachmentBatchDelete({
            bizId: this.rowData.id,
            bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER,
            urls: deleteFile,
          });
        }
        if (addFile.length > 0) {
          await fileAttachmentAdd({
            bizId: this.rowData.id,
            bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER,
            attachmentFileReqs: addFile,
          });
        }
        this.$emit('handleSuccess', this.rowData);
        this.handleClose();
      } else {
        this.$message({ type: 'warning', message: '请上传“四证”至附件后进行确认。' });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async handleDownload() {
      await downLoadTemplate({ templateId: 'receipt_attachments_file_standard_description' }, '进口品管理要求');
    },
    handleOpenFile(file) {
      this.downloadUrlFile(file.filePath, file.fileName);
    },
    handleAllUpload() {
      this.fileData.forEach((item) => {
        this.downloadUrlFile(item.filePath, item.fileName);
      });
    },
    handleRemove({ index }) {
      this.fileData = this.fileData.filter((item, i) => index !== i);
    },
    handleAllRemove() {
      this.fileData = [];
    },
    // 保存到本地并自动点击
    saveAs(data, name) {
      const urlObject = window.URL || window.webkitURL || window;
      const exportblob = new Blob([data]);
      const link = document.createElement('a');
      link.href = urlObject.createObjectURL(exportblob);
      link.download = name;
      link.click();
    },
    // 下载含有url的文件
    downloadUrlFile(url, fileName) {
      const url2 = url.replace(/\\/g, '/');
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url2, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200 && xhr.readyState === 4) {
          // 获取文件blob数据并保存
          this.saveAs(xhr.response, fileName);
        }
      };
      xhr.send();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-box {
  p {
    text-indent: 2em;
    margin: 16px;
  }
}

.upload-box {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: baseline;
  }
}

.upload-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    &:first-of-type {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      width: 120px;

      .el-button + .el-button {
        margin-left: 30px;
      }
    }
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>

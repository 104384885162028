import { ElMessage } from 'element-plus';
import { fileAttachmentAdd, fileAttachmentBatchDelete } from '../../api';
import { BIZ_TYPE_ENUM } from '../../constant';
import data from '../data';
import handleClose from './onClose';

const splitFiles = (newUploadFile, oldUploadFile) => {
  let addFile = []; let deleteFile = [];
  addFile = newUploadFile.filter((newItem) => !(newItem.id || newItem.bizId)).map((item) => ({
    attachmentUrl: item.filePath,
    attachmentName: item.fileName,
  }));
  deleteFile = oldUploadFile
    .filter((oldItem) => !newUploadFile.some(
      (newItem) => newItem.id === oldItem.id,
    ))
    .map((item) => item.filePath);
  return {
    addFile,
    deleteFile,
  };
};

const handleSubmit = async () => {
  const {
    bizId,
    uploadFile,
    loading,
    oldUploadFile,
    isImport,
  } = data();
  if (isImport.value && uploadFile.value.length < 1) {
    ElMessage({
      message: '进口货品必须上传“四证”。',
      type: 'warning',
    });
    return;
  }
  loading.handleSubmit = true;
  const { addFile, deleteFile } = splitFiles(uploadFile.value, oldUploadFile.value);
  try {
    if (deleteFile.length > 0) {
      await fileAttachmentBatchDelete({
        bizId: bizId.value,
        bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER,
        urls: deleteFile,
      });
    }
    if (addFile.length > 0) {
      await fileAttachmentAdd({
        bizId: bizId.value,
        bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER,
        attachmentFileReqs: addFile,
      });
    }
    handleClose();
  } finally {
    loading.handleSubmit = false;
  }
};
export default handleSubmit;

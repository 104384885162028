import data from '../data';

const handleClose = () => {
  const {
    bizId,
    dialogVisible,
    uploadFile,
    isImport,
    oldUploadFile,
  } = data();
  bizId.value = null;
  uploadFile.value = [];
  oldUploadFile.value = [];
  isImport.value = false;
  dialogVisible.value = false;
};

export default handleClose;

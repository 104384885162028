<template>
  <div>
    <pl-block>
      <PlForm
        v-model="search"
        mode="search"
        :fields="serchFields"
        @submit="handleQueryFrom"
        @reset="handleQueryFrom"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="search.shipperId"
          />
        </template>
      </PlForm>
    </pl-block>

    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.loadInboundOrderPage"
        :data="tableData"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="handlePaginationChange"
        @selection-change="handleSelectionChange"
      >
        <template #tableHeaderLeft>
          <nh-button
            type="primary"
            :track="{
              trackName: '入库管理/入库单管理/[新增]'
            }"
            @click="headleAddInboundOrder('')"
          >
            新增
          </nh-button>
          <nh-button
            type="grey"
            :track="{
              trackName: '入库管理/入库单管理/[打印收货单]'
            }"
            @click="handlePrint"
          >
            打印收货单
          </nh-button>
          <nh-button
            type="grey"
            :track="{
              trackName: '入库管理/入库单管理/[打印入库交接单]'
            }"
            @click="handlePrintReceipt"
          >
            打印入库交接单
          </nh-button>
        </template>
        <template #tableHeaderRight>
          <nh-button
            type="grey"
            :track="{
              trackName: '入库管理/入库单管理/[导入]'
            }"
            @click="handleImport"
          >
            导入
          </nh-button>
          <nh-button
            type="grey"
            :track="{
              trackName: '入库管理/入库单管理/[导出挂单明细]'
            }"
            @click="handleExport"
          >
            导出挂单明细
          </nh-button>
        </template>
        <template #receiptOrderNo="{row}">
          <nh-link
            :to="{}"
            :text="row.receiptOrderNo"
            @click="handleDetails(row)"
          />
          <div v-if="row.isImport" class="import-box">
            进口
          </div>
        </template>
        <template #action="{row}">
          <nh-button
            v-if="RECEIPT_STSTUS_ENUM_VALUE.PRE_AUDIT === row.receiptStatus"
            type="text"
            @click="handleAudit(row)"
          >
            审核
          </nh-button>
          <nh-button
            v-if="showCancelButton(row)"
            type="text"
            @click="handleInboundOrderCancel(row.id)"
          >
            取消
          </nh-button>
          <nh-button
            v-if="showEditButton(row)"
            type="text"
            @click="headleEditInboundOrder(row.id)"
          >
            编辑
          </nh-button>
          <nh-button
            v-if="RECEIPT_STSTUS_ENUM_VALUE.PRE_CHECK_IN === row.receiptStatus"
            type="text"
            @click="headleArrivalRegistration(row)"
          >
            到货登记
          </nh-button>
          <nh-button
            v-if="showUploadButton(row)"
            type="text"
            @click="headleManageAttach(row)"
          >
            附件管理
          </nh-button>
        </template>
      </PlTable>
    </pl-block>

    <InboundCheckDialog
      ref="InboundCheckDialog"
      @submitSuccess="handleInboundCheckDialogSubmitSuccess"
    />
    <FourCardTest
      ref="fourCardTest"
      @handleSuccess="fourCardSuccess"
    />
    <nh-data-import
      ref="DataImport"
      class="inbound-order_import"
      :err-msg-list="errMsgList"
      :is-upload="isUpload"
      :data-amount="goodsCount"
      :track="{
        name_zh: '入库管理/入库单管理/弹窗-导入',
        trackName: $route.path
      }"
      :confirm-loading="loading.handleGoodsImportSubmitSuccess"
      @submitSuccess="handleGoodsImportSubmitSuccess"
      @cancel="handleGoodsImportCancel"
      @clearAll="handleGoodsImportClearAll"
    >
      <template #titleBtnLeft>
        <el-row :gutter="24">
          <el-col :span="5">
            <el-upload
              ref="uploadOne"
              :limit="1"
              :show-file-list="false"
              name="file"
              action=""
              multiple
              :http-request="warehouseGoodsImport"
              :on-success="handleUploadOneSuccess"
            >
              <nh-button type="primary" :loading="loading.warehouseGoodsImport">
                导入
              </nh-button>
            </el-upload>
          </el-col>
          <el-col :span="8">
            <nh-button
              type="primary"
              @click="handleDownTemplate"
            >
              入库模板下载
            </nh-button>
          </el-col>
          <el-col :span="10">
            <nh-button
              type="primary"
              @click="handleDownOnceTemplate"
            >
              入库单一键上架模板下载
            </nh-button>
          </el-col>
        </el-row>
      </template>
    </nh-data-import>
  </div>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="订单基本信息"
    size="1111px"
    :track="{
      name_zh: '入库管理/入库单管理/抽屉-详情',
      trackName: $route.path
    }"
  >
    <InWarehouseDrawer v-if="drawerVisible" :inbound-order-id="inboundOrderId" />
  </nh-drawer>
  <ManageAttachments ref="manageAttachDrawer" />
</template>
<script>
import loadingMixin from '@thales/loading';
import InWarehouse from '@/constant/pageNames/inWarehouse';
import FourCardTest from './fourCardTest/index.vue';
import {
  inboundOrderPage,
  inboundOrderCancel,
  receiptOrderStatusUpdate,
  receiptOrderImportCheck,
  downLoadTemplate,
  inboundOrderSave,
  unfinishedExport,
  fileAttachmentQuery,
  batchSaveOneClick,
} from './api';
import mixin from './mixin';
import { TABLECOLUMN, serchFields } from './fileds';
import {
  getbeforeTimeByRangeTime,
  getAfterTimeByRangeTime,
  resetFormRangeTime,
  timeFormat,
} from './utils';
import { SOURCE_ENUM, RECEIPT_STSTUS_ENUM_VALUE } from './constant';
import InboundCheckDialog from './inboundCheckDialog/index.vue';
import InWarehouseDrawer from './detail/index.vue';
import ManageAttachments from './ManageAttachments/ManageAttachments.vue';
// 业务类型
const BIZ_TYPE_ENUM = {
  RECEIPT_ORDER: 'RECEIPT_ORDER', // 入库单
  DELIVER_ORDER: 'DELIVER_ORDER', // 出库单
};
export default {
  name: InWarehouse.INBOUND_ORDER,
  components: {
    InboundCheckDialog,
    FourCardTest,
    InWarehouseDrawer,
    ManageAttachments,
  },
  mixins: [loadingMixin, mixin],
  provide: {
    nameZh: '入库管理/入库单管理/',
  },
  data() {
    return {
      search: {
        shipperId: null,
      },
      TABLECOLUMN,
      serchFields,
      RECEIPT_STSTUS_ENUM_VALUE,
      tableData: [],
      loading: {
        loadInboundOrderPage: false,
        handleDeleteFile: false,
        handleGoodsImportSubmitSuccess: false,
        warehouseGoodsImport: false,
      },
      selectIds: [],
      goodsCount: 0,
      errMsgList: [],
      allInboundOrder: [],
      isUpload: false,
      fileData: [],
      inboundOrderId: 0,
      drawerVisible: false,
      repIsUpload: false,
    };
  },
  activated() {
    this.loadInboundOrderPage();
  },
  methods: {
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },

    handleQueryFrom() {
      this.pagination.page = 1;

      this.loadInboundOrderPage();
    },
    handlePaginationChange() {
      this.loadInboundOrderPage();
    },
    headleAddInboundOrder(id) {
      this.$router.push({ path: '/in-warehouse/inbound-order/add', query: { id } });
    },
    headleEditInboundOrder(id) {
      this.$router.push({ path: '/in-warehouse/inbound-order/edit', query: { id } });
    },
    async headleArrivalRegistration(rowData) {
      if (rowData.isImport) {
        await this.fileAttachmentQuery(rowData);
        this.$refs.fourCardTest.init(rowData, this.fileData);
      } else {
        this.$router.push({ path: '/in-warehouse/inbound-order/arrivalRegistration', query: { id: rowData.id } });
      }
    },
    // 查询订单附件列表
    async fileAttachmentQuery(rowData) {
      this.fileData = [];
      const { fileAttachments = [] } = await fileAttachmentQuery(
        { bizId: rowData.id, bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER },
      );
      this.fileData = fileAttachments.map((item) => ({
        ...item, url: item.filePath, fileTime: timeFormat(item.updateTime),
      }));
    },
    fourCardSuccess(row) {
      this.$router.push({ path: '/in-warehouse/inbound-order/arrivalRegistration', query: { id: row.id } });
    },
    async handleInboundOrderCancel(receiptOrderId) {
      await this.$confirm('是否确定取消该入库单?', '入库单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await inboundOrderCancel({ receiptOrderId });
      await this.$message({
        message: '取消成功',
        type: 'success',
      });
      this.loadInboundOrderPage();
    },
    async handlePrint() {
      if (this.selectIds.length <= 0) {
        await this.$message({
          message: '必须选择一条数据后才能进行打印',
          type: 'warn',
        });
      } else {
        const routeUrl = this.$router.resolve({
          path: '/print/storage-list',
          query: { ids: this.selectIds },
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    handleAudit(rowData) {
      this.$refs.InboundCheckDialog.show(rowData);
    },
    handleInboundCheckDialogSubmitSuccess(data) {
      this.receiptOrderStatusUpdate(data);
    },
    handleImport() {
      this.$refs.DataImport.showDialog();
      // this.$refs.InboundOrderImport.showInboundOrderImportDialog();
    },
    handleInboundOrderImportSubmitSuccess() {
      this.loadInboundOrderPage();
    },
    showCancelButton(rowData) {
      let result = false;
      if (rowData.source === SOURCE_ENUM.WMS) {
        result = rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_AUDIT
       || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_CHECK_IN
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT;
      }
      return result;
    },
    showEditButton(rowData) {
      return rowData.source === SOURCE_ENUM.WMS
      && (rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_AUDIT
       || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_CHECK_IN
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT);
    },
    // 待审核、待登记、待验收、验收中、待入库、入库中、待上架、上架中时
    showUploadButton(rowData) {
      return (rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_AUDIT
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_CHECK_IN
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.ACCEPTING
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_STORE
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.DOING_STORE
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_PUT_ON_SHELVES
        || rowData.receiptStatus === RECEIPT_STSTUS_ENUM_VALUE.DOING_PUT_ON_SHELVES);
    },
    async loadInboundOrderPage() {
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await inboundOrderPage({ ...pagination, sort: 'createTime__DESC' }, params);
      this.tableData = result.records;
      this.pagination.total = result.total;
    },
    buildParams() {
      // 调用表单重置 时间控件会跳到 1970 年，增加default 解决问题后 导致 重置 会携带默认值，所以在这里判定，只有一个值的时候即为重置
      const newFormData = resetFormRangeTime(this.search, ['createTime', 'bookingTime', 'beginAcceptanceTime', 'endAcceptanceTime', 'finishUpperShelfTime']);
      return {
        ...this.search,
        ...newFormData,
        beforeCreateTime: getbeforeTimeByRangeTime(this.search.createTime),
        afterCreateTime: getAfterTimeByRangeTime(this.search.createTime),

        beforeArrivalTime: getbeforeTimeByRangeTime(this.search.bookingTime),
        afterArrivalTime: getAfterTimeByRangeTime(this.search.bookingTime),

        beforeBeginAcceptanceTime: getbeforeTimeByRangeTime(this.search.beginAcceptanceTime),
        afterBeginAcceptanceTime: getAfterTimeByRangeTime(this.search.beginAcceptanceTime),

        beforeEndAcceptanceTime: getbeforeTimeByRangeTime(this.search.endAcceptanceTime),
        afterEndAcceptanceTime: getAfterTimeByRangeTime(this.search.endAcceptanceTime),

        beforeFinishUpperShelfTime: getbeforeTimeByRangeTime(this.search.finishUpperShelfTime),
        afterFinishUpperShelfTime: getAfterTimeByRangeTime(this.search.finishUpperShelfTime),
      };
    },
    async receiptOrderStatusUpdate(data) {
      const result = await receiptOrderStatusUpdate({}, {
        receiptOrderId: data.id,
        bookingTime: data.bookingTime,
        exceptNextStatus: 'PRE_CHECK_IN',
      });
      if (result && result.success) {
        this.$message({ type: 'success', message: '审核成功！' });
        this.loadInboundOrderPage();
      }
    },
    async warehouseGoodsImport(file) {
      this.goodsCount = null;
      const fd = new FormData();
      fd.append('file', file.file);
      const {
        batchSaveReqs, errorMsg = [], goodsCount, isUpper: repIsUpload,
      } = await receiptOrderImportCheck(fd);
      this.goodsCount = goodsCount;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
      this.allInboundOrder = [...this.allInboundOrder, ...batchSaveReqs];
      this.isUpload = true;
      this.repIsUpload = repIsUpload;
    },
    handleUploadOneSuccess() {
      this.$refs.uploadOne.clearFiles();
    },
    async handleDownTemplate() {
      await downLoadTemplate({ templateId: 'receipt_order_template_id' }, '入库单导入模板');
    },
    async handleDownOnceTemplate() {
      await downLoadTemplate({ templateId: 'receipt_one_click_import_id' }, '入库单一键上架导入模板');
    },
    async inboundOrderSave() {
      await inboundOrderSave(this.allInboundOrder);
    },
    async handleGoodsImportSubmitSuccess() {
      if (this.errMsgList && this.errMsgList.length <= 0) {
        if (this.repIsUpload) {
          await batchSaveOneClick({ batchSaveReqs: this.allInboundOrder });
        } else {
          await this.inboundOrderSave();
        }
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.handleGoodsImportClearAll();
        this.$refs.DataImport.handleClose();
      } else {
        this.$message({
          message: '请根据错误信息修改后再上传',
          type: 'warning',
        });
      }
      this.handleQueryFrom();
    },
    handleGoodsImportCancel() {
      this.handleGoodsImportClearAll();
      this.$refs.DataImport.handleClose();
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.errMsgList = [];
      this.goodsCount = 0;
      this.allInboundOrder = [];
    },
    async handleExport() {
      const params = this.buildParams();
      unfinishedExport(params, { fileName: '挂单明细', extension: 'xlsx' });
    },
    async handlePrintReceipt() {
      if (this.selectIds.length <= 0) {
        await this.$message({
          message: '必须选择一条数据后才能进行打印',
          type: 'warn',
        });
      } else {
        const routeUrl = this.$router.resolve({
          path: '/print/warehouse-receipt-list',
          query: { ids: this.selectIds },
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    headleManageAttach(row) {
      this.$refs.manageAttachDrawer.init(row);
    },
    handleDetails({ id }) {
      this.inboundOrderId = id;
      this.drawerVisible = true;
    },
    handleClose() {
      this.drawerVisible = false;
      this.loadInboundOrderPage();
    },
  },
};
</script>
<style scoped lang="scss">
.action {
  padding: 15px 0;
}

.upload-btn {
  display: inline-block;
}

.delete-file {
  margin-left: 16px;
}

.full-content {
  .cursor {
    cursor: pointer;
  }
}

.cursor-color {
  color: aquamarine;
}

.inbound-order_import {
  :deep(.dialog-import) {
    min-width: 660px;
  }
}

.import-box {
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  color: #fe5e08;
  background: #f9f1ec;
  border-radius: 4px;
  border: 1px solid #fbbb98;
  display: inline-block;
}
</style>

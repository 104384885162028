const splitFiles = (newUploadFile, oldUploadFile) => {
  let addFile = []; let deleteFile = [];
  addFile = newUploadFile.filter((newItem) => !(newItem.id || newItem.bizId)).map((item) => ({
    attachmentUrl: item.filePath,
    attachmentName: item.fileName,
  }));
  deleteFile = oldUploadFile
    .filter((oldItem) => !newUploadFile.some(
      (newItem) => newItem.filePath === oldItem.filePath,
    ))
    .map((item) => item.filePath);

  return {
    addFile,
    deleteFile,
  };
};

export default splitFiles;

import Moment from '@/utils/moment';
import { ElMessage } from 'element-plus';
import { cloneDeep } from 'lodash';
import { fileAttachmentQuery } from '../../api';
import data from '../data';
// 查询订单附件列表
const getAttachmentFiles = async (rowData) => {
  const {
    uploadFile,
    oldUploadFile,
    loading,
  } = data();
  loading.getUploadFiles = true;
  try {
    const { fileAttachments = [] } = await fileAttachmentQuery(
      { bizId: rowData.id, bizTypeEnum: 'RECEIPT_ORDER' },
    );
    uploadFile.value = fileAttachments.map((item) => ({
      ...item,
      url: item.filePath,
      fileTime: Moment.format(item.updateTime),
      status: 'success',
    }));
    oldUploadFile.value = cloneDeep(uploadFile.value);
  } finally {
    loading.getUploadFiles = false;
  }
};

const handleRemove = ({ index }) => {
  const {
    uploadFile,
    isImport,
  } = data();
  if (isImport.value && uploadFile.value.length === 1) {
    ElMessage({
      message: '进口货品必须上传”四证“，无法继续删除。',
      type: 'warning',
    });
    return;
  }
  uploadFile.value = uploadFile.value.filter((item, i) => index !== i);
};
const handleAllRemove = () => {
  const {
    uploadFile,
  } = data();
  uploadFile.value = [];
};
export {
  getAttachmentFiles,
  handleRemove,
  handleAllRemove,
};

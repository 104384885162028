import { ref, reactive } from 'vue';

const dialogVisible = ref(false);
const uploadFile = ref([]);
const oldUploadFile = ref([]);
const bizId = ref();
const isImport = ref(false);
const loading = reactive({
  getUploadFiles: false,
  handleSubmit: false,
});

export default () => ({
  bizId,
  isImport,
  dialogVisible,
  uploadFile,
  oldUploadFile,
  loading,
});

<template>
  <nh-drawer
    v-model="dialogVisible"
    v-loading="loading.getUploadFiles"
    :before-close="handleClose"
    destroy-on-close
    title="附件管理"
    size="704px"
  >
    <nh-upload-attachment
      v-model:upload-file="uploadFile"
      :is-import="isImport"
      @onRemove="handleRemove"
      @onAllRemove="handleAllRemove"
    />
    <template #footer>
      <div class="footer">
        <nh-button
          v-loading="loading.handleSubmit"
          type="primary"
          @click="handleSubmit"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>

<script setup>
import { defineExpose } from 'vue';
import data from './data';
import {
  getAttachmentFiles,
  handleRemove,
  handleAllRemove,
} from './uses/uploadFiles';
import handleSubmit from './uses/onSubmit';
import handleClose from './uses/onClose';

const {
  bizId,
  isImport,
  dialogVisible,
  uploadFile,
  loading,
} = data();

const init = (row) => {
  bizId.value = row.id;
  isImport.value = row.isImport;
  dialogVisible.value = true;
  getAttachmentFiles(row);
};

defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.content-box {
  p {
    text-indent: 2em;
    margin: 16px;
  }
}

.upload-box {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: baseline;
  }
}

.upload-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    &:first-of-type {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      width: 120px;

      .el-button + .el-button {
        margin-left: 30px;
      }
    }
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>

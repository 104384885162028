<template>
  <div>
    <nh-drawer
      v-model="visible"
      title="入库单审核"
      destroy-on-close
      size="1112px"
      :before-close="handleClose"
      :track="{
        name_zh: `入库管理/入库单管理/抽屉-审核`,
        trackName: $route.path
      }"
    >
      <el-form
        ref="checkForm"
        :model="checkForm"
        :rules="rules"
        inline
        label-width="120px"
      >
        <el-form-item label="确认提货时间:" prop="bookingTime">
          <el-date-picker
            v-model="checkForm.bookingTime"
            type="date"
            :disabled-date="bookingTimeOptions"
            placeholder="选择提货时间"
          />
        </el-form-item>
        <el-form-item>
          <nh-button
            type="primary"
            :loading="loading.handleSubmit"
            @click="handleSubmit('checkForm')"
          >
            确 定
          </nh-button>
        </el-form-item>
      </el-form>
      <InboundOrderDetail :inbound-order-id="checkForm.id" :is-only-detail="false" />
    </nh-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import InboundOrderDetail from '../detail/index.vue';

export default {
  name: 'InboundCheckDialog',
  components: { InboundOrderDetail },
  mixins: [loadingMixin],
  props: {
    deliverAreaDocks: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submitSuccess'],
  data() {
    return {
      checkForm: {
        cars: [],
      },
      checkFormCope: {},
      rules: {
        bookingTime: [
          { required: true, message: '请输入预约入库时间', trigger: 'blur' },
        ],
      },
      loading: {
        handleSubmit: false,
      },
      visible: false,
      bookingTimeOptions: (time) => time < moment().subtract(1, 'days').valueOf(),
    };
  },
  watch: {
  },
  created() {},
  methods: {
    show(info) {
      const bookingTime = info.bookingTime > moment().valueOf()
        ? info.bookingTime : moment().valueOf();
      this.checkForm = {
        ...info,
        bookingTime,
      };
      this.visible = true;
    },
    handleClose() {
      this.$refs.checkForm.resetFields();
      this.visible = false;
    },
    async handleSubmit(formName) {
      const valid = await this.$refs[formName].validate();
      if (valid) {
        if (this.checkForm.bookingTime >= moment().valueOf()) {
          this.$emit('submitSuccess', this.checkForm);
          this.handleClose();
        } else {
          this.$message({ type: 'warning', message: '请选择当前时间之后的时间' });
        }
      }
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

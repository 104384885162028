import moment from 'moment';
import { FormType } from '@/constant/form';
import { StoreBillTypeText } from '@/constant/task';
import { DateTime } from '@/constant/tableConfig';

import {
  SOURCE,
  SOURCE_ENUM,
  RECEIPT_STSTUS,
  RECEIPT_STSTUS_ENUM,
  RECEIPT_TYPE,
} from './constant';

export const ADD_FORM_FIELDS = (handling) => {
  const outsourceLoadAndUnload = handling ? [{
    label: '是否外包装卸',
    prop: 'outsourceHandling',
    component: FormType.SELECT,
    colAttrs: {
      span: 8,
    },
    formItemAttrs: {
      rules: [{ required: true, message: '请选择', trigger: 'blur' }],
    },
  }] : [];
  return [{
    label: '所属货主',
    prop: 'customer',
    component: FormType.SELECT,
    colAttrs: {
      span: 8,
    },
    formItemAttrs: {
      rules: [{ required: true, message: '请输入所属货主', trigger: 'blur' }],
    },
  }, {
    label: '入库单类型',
    prop: 'storeTypeCode',
    component: FormType.SELECT,
    colAttrs: {
      span: 8,
    },
    formItemAttrs: {
      rules: [{ required: true, message: '请选择入库单类型', trigger: 'change' }],
    },
  }, {
    label: '预约送货时间',
    prop: 'storeTime',
    component: FormType.DATE_PICKER,
    colAttrs: {
      span: 8,
    },
    componentAttrs: {
      type: 'datetime',
      disabledDate: (time) => time < moment().subtract(1, 'days').valueOf(),
    },
    formItemAttrs: {
      rules: [{ required: true, message: '请选择预约送货时间', trigger: 'change' }],
    },
  }, {
    label: '货主订单号',
    prop: 'freshScmOrderNo',
    component: FormType.INPUT,
    colAttrs: {
      span: 8,
    },
    formItemAttrs: {
      rules: [{ required: false, message: '请输入货主订单号', trigger: 'blur' }],
    },
  }, {
    label: '供应商',
    prop: 'supplierName',
    component: FormType.INPUT,
    colAttrs: {
      span: 8,
    },
    componentAttrs: {
      maxlength: 32,
    },
  }, {
    colAttrs: {
      span: 8,
    },
  }, {
    label: '是否需要装卸',
    prop: 'handling',
    component: FormType.SELECT,
    colAttrs: {
      span: 8,
    },
    formItemAttrs: {
      rules: [{ required: true, message: '请选择', trigger: 'blur' }],
    },
  },
  ...outsourceLoadAndUnload,
  {
    colAttrs: {
      span: handling ? 8 : 16,
    },
  }, {
    label: '备注',
    prop: 'note',
    component: FormType.INPUT,
    colAttrs: {
      span: 8,
    },
    componentAttrs: {
      type: 'textarea',
      maxlength: 500,
      showWordLimit: true,
    },
  }, {
    label: '附件',
    prop: 'files',
    component: FormType.INPUT,
    colAttrs: {
      span: 24,
    },
  }];
};

export const TABLECOLUMN = [{
  prop: 'selection',
  type: 'selection',
  width: 50,
  align: 'center',
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '入库单号',
  prop: 'receiptOrderNo',
  width: 180,
}, {
  label: '外部单号',
  prop: 'externalOrderNo',
  width: 180,
}, {
  label: '货主订单号',
  prop: 'customerOrderNo',
  width: 180,
}, {
  label: '入库单状态',
  prop: 'receiptStatus',
  width: 120,
  formatter: (row) => RECEIPT_STSTUS_ENUM[row.receiptStatus],
}, {
  label: '货主名称',
  prop: 'shipperName',
  width: 140,
}, {
  label: '入库单类型',
  prop: 'receiptType',
  width: 120,
  formatter: (row) => StoreBillTypeText[row.receiptType],
}, {
  label: '供应商',
  prop: 'supplierName',
  width: 120,
}, {
  label: '车牌号',
  prop: 'plateNo',
  width: 120,
}, {
  label: '预约总数量(默认单位)',
  prop: 'defaultNum',
  width: 100,
}, {
  label: '预约总重量(kg)',
  prop: 'bookingTotalWeight',
  width: 120,
}, {
  label: '预约总体积(m³)',
  prop: 'bookingTotalVolume',
  width: 100,
}, {
  label: '总SKU数',
  prop: 'skuNum',
  width: 120,
}, {
  label: '已验收数量',
  prop: 'acceptanceNum',
  width: 140,
}, {
  label: '已上架数量',
  prop: 'upperShelfNum',
  width: 140,
}, {
  label: '订单来源',
  prop: 'source',
  formatter: (row) => SOURCE_ENUM[row.source],
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '创建人',
  prop: 'createUserName',
  width: 140,
}, {
  label: '到货登记时间',
  prop: 'arrivalTime',
  ...DateTime,
}, {
  label: '验收确认时间',
  prop: 'endAcceptanceTime',
  ...DateTime,
}, {
  label: '完成上架时间',
  prop: 'finishUpperShelfTime',
  ...DateTime,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 180,
}];

export const serchFields = [
  {
    label: '入库单号',
    prop: 'receiptOrderNo',
    component: FormType.INPUT,
  }, {
    label: '入库单类型',
    prop: 'receiptType',
    component: FormType.SELECT,
    options: RECEIPT_TYPE,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '外部单号',
    prop: 'externalOrderNo',
    component: FormType.INPUT,
  }, {
    label: '货主订单号',
    prop: 'customerOrderNo',
    component: FormType.INPUT,
  }, {
    label: '供应商',
    prop: 'supplierName',
    component: FormType.INPUT,
  }, {
    label: '车牌号',
    prop: 'plateNo',
    component: FormType.INPUT,
  }, {
    label: '订单来源',
    prop: 'source',
    component: FormType.SELECT,
    options: SOURCE,
  }, {
    label: '入库单状态',
    prop: 'receiptStatus',
    component: FormType.SELECT,
    options: RECEIPT_STSTUS,
  }, {
    label: '创建时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
    },
  }, {
    label: '到货登记时间',
    prop: 'bookingTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
    },
  }, {
    label: '开始验收时间',
    prop: 'beginAcceptanceTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
    },
  }, {
    label: '验收确认时间',
    prop: 'endAcceptanceTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
    },
  },
  {
    label: '完成上架时间',
    prop: 'finishUpperShelfTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
    },
  },
];
